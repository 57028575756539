import React from 'react'

const Skill = ({ icon, icon1, title, text, text1, text2, text3,urlText,url }) => {
 return (
  <article className="skill">
   <span className="skill-icon">
    <i className={icon}></i>
    <h4 className="skill-title">{title}</h4>
    {text && (
     <p className="skill-text">
      {text}
     </p>
    )}
    {text1 && (
     <p className="skill-text">
      <i className={icon1}></i> &nbsp; {text1}
     </p>
    )}
    {text2 && (
     <p className="skill-text">
      <i className={icon1}></i> &nbsp; {text2}
     </p>
    )}
    {text3 && (
     <p className="skill-text">
      <i className={icon1}></i> &nbsp; {text3}
     </p>
    )}
    {url && (
     <a href={url} class="btn skill-btn scroll-link">{urlText}</a>
    )}
   </span>
  </article>
 )
}

export default Skill
