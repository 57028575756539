import React from 'react'

const Fundraiserprogressbar = ({ targetAmount, currentAmount }) => {
 const progress = Math.min(100, (currentAmount / targetAmount) * 100);

 const progressBarStyle = {
  height: '30px',
  width: '100%',
  backgroundColor: '#ddd',
 };

 const getProgressColor = (progress) => {
  if (progress <= 30) {
   return 'red';
  } else if (progress <= 70) {
   return 'yellow';
  } else {
   return 'green';
  }
 };

 const progressStyle = {
  height: '100%',
  width: `${progress}%`,
  backgroundColor: getProgressColor(progress),
  transition: 'width 0.5s ease-in-out, background-color 0.5s ease-in-out',
 };

 return (
  <div style={progressBarStyle}>
   <div style={progressStyle} />
  </div>
 )
}

export default Fundraiserprogressbar
