import React from 'react'
// components
import Service from './Service'

import Service_Image_1 from '../../assets/images/microsoft-dynamics-365.jpg'
import Service_Image_2 from '../../assets/images/web.jpg'
import Service_Image_3 from '../../assets/images/mobile.jpg'
import Service_Image_4 from '../../assets/images/training.jpg'

// styles
import './Services.css'

const Services = () => {
     return (
          <section className="services clearfix">
               <div className="section-title services-title">
                    <h3>explore</h3>
                    <h2>our services</h2>
               </div>
               <div className="section-center clearfix">
                    <Service
                         id="1"
                         title="Dynamics 365"
                         description="Microsoft Dynamics 365 is one of the market leading business management platforms. Its comprehensive suite of specifically designed business applications enable your organisation to grow and transform to meet the needs of your customers and capture new opportunities."
                         image={Service_Image_1}
                         icon="fas fa-mortar-pestle fa-fw"
                         url="/services"
                    />
                    <Service
                         id="2"
                         title="Web Development"
                         description="Whether you’re launching a new brand, need a fresh look for an existing website, want to migrate to another platform, or need a bespoke site with unique functionality, our team will work with you and to your brief to achieve the results you need."
                         image={Service_Image_2}
                         icon="fas fa-mortar-pestle fa-fw"
                         url="/services"
                    />
                    <Service
                         id="3"
                         title="Mobile Development"
                         description="Custom-built mobile apps designed to fulfil a range of business functions. Whatever your business objective, we’ll plan and develop a mobile app that helps you get to where you need to be. We develop cross platform apps that run on iOS or Android using React Native or Xamarin forms."
                         image={Service_Image_3}
                         icon="fas fa-mortar-pestle fa-fw"
                         url="/services"
                    />
                    <Service
                         id="4"
                         title="Training"
                         description="We offer training to help you unlock the full capability of Dynamics and the Microsoft Power Platform for your users and teams.

We offer bespoke training for user groups, and one-to-one tuition, in addition to Dynamics 365 training modules, available through our eLearning platform."
                         image={Service_Image_4}
                         icon="fas fa-mortar-pestle fa-fw"
                         url="/services"
                    />
               </div>
          </section>
     )
}

export default Services
