import React from 'react'
import { Link } from 'react-router-dom'

// Hooks
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext';

// styles
import './Footerbar.css'

export default function Footerbar() {

 const { logout, isPending } = useLogout()
 const { user, authIsReady } = useAuthContext()

 return (
  // footer
  <footer class="footer">


   {/* Footer Links */}
   <ul class="footer-links">
    <li>
     <Link to='/home' className='footer-link'>home</Link>
    </li>
    <li>
     <Link to='/about' className='footer-link'>about</Link>
    </li>
    <li>
     <Link to='/faqs' className='footer-link'>FAQs</Link>
    </li>
    <li>
     <Link to='/contact' className='footer-link'>contact</Link>
    </li>
     </ul>
   {/* footer icons */}
   <ul class="footer-icons">
    <li>
     <a href="https://facebook.com" class="footer-icon" target="_blank">
      <i class="fab fa-facebook"></i>
     </a>
    </li>
    <li>
     <a href="https://twitter.com" class="footer-icon" target="_blank">
      <i class="fab fa-twitter"></i>
     </a>
    </li>
    <li>
     <a
      href="https://ssquarespace.com"
      class="footer-icon"
      target="_blank"
     >
      <i class="fab fa-squarespace"></i>
     </a>
    </li>
   </ul>
   {/* end of footer icons */}
   <p class="copyright">
    copyright &copy; Peace Welfare Trust <span>{new Date().getFullYear()}</span>.<span className='rights'>all rights reserved</span>

   </p>
  </footer>
 )
}
