import React from 'react'
import { FaBehance, FaFacebook, FaLinkedin, FaTwitter } from 'react-icons/fa'
export const links = [
 {
  id: 1,
  url: '/',
  text: 'home',
 },
 {
  id: 2,
  url: '/about',
  text: 'about',
 },
/*  {
  id: 3,
  url: '/frequentquestions',
  text: 'FAQs',
 }, */
 // {
 //  id: 5,
 //  url: '/projects',
 //  text: 'projects',
 // },
 {
  id: 9,
  url: '/contact',
  text: 'contact',
 },
]

export const social = [
 {
  id: 11,
  url: 'https://www.facebook.com',
  icon: <FaFacebook />,
 },
 {
  id: 12,
  url: 'https://www.twitter.com',
  icon: <FaTwitter />,
 },
 {
  id: 13,
  url: 'https://www.linkedin.com',
  icon: <FaLinkedin />,
 },
 {
  id: 14,
  url: 'https://www.behance.net',
  icon: <FaBehance />,
 },
]
