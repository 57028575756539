import React from 'react'

//styles
import './Header.css'

//components
import Navbar from '../navbar/Navbar'

export default function Header() {
 return (
  <header id="home">
   {/* hero */}
   <div className="hero">
    <div className="hero-banner">
     <h2 className='hero-title'>Fund raising Appeal</h2>
     <h3>
      For a new masjid in Nuneaton
     </h3>
     <p>
      Build your house in Jannah
     </p>
     <a href="https://pay.easydonate.uk/peacewelfaretrust" class="btn hero-btn scroll-link">Donate</a>
    </div>
   </div>
  </header>
 )
}
