import React, { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import Select from 'react-select'

// hooks
import { useCollection } from '../../../../hooks/useCollection'
import { useAuthContext } from '../../../../hooks/useAuthContext'
import { useFirestore } from '../../../../hooks/useFirestore'
import { timestamp } from '../../../../firebase/config'

// components
import Sidebar from '../../../../components/navigation/Sidebar'

// styles
import './CreateProject.css'

const categories = [
  { value: 'masjid', label: 'Masjid' },
  { value: 'madrassa', label: 'Madrassa' },
  { value: 'fundraising', label: 'Fund Raising' },
  { value: 'other', label: 'Other' },
]

const statuses = [
  { value: 'pending', label: 'Pending' },
  { value: 'inprogress', label: 'In Progress' },
  { value: 'completed', label: 'Completed' },
  { value: 'cancelled', label: 'Cancelled' },
  { value: 'onhold', label: 'On Hold' },
]

const priorities = [
  { value: 'critical', label: 'Critical' },
  { value: 'high', label: 'High' },
  { value: 'medium', label: 'Medium' },
  { value: 'low', label: 'Low' },
]

const projecttypes = [
  { value: 'private', label: 'Private' },
  { value: 'public', label: 'Public' },
]

export default function CreateProject() {
  const history = useHistory()
  const { addDocument, response } = useFirestore('projects')
  const { documents } = useCollection('users')
  const [users, setUsers] = useState([])
  const { user } = useAuthContext()

  // form fields
  const [name, setName] = useState('')
  const [targetAmount, setTargetAmount] = useState('')
  const [details, setDetails] = useState('')
  const [dueDate, setDueDate] = useState('')
  const [projectType, setProjectType] = useState('')
  const [category, setCategory] = useState('')
  const [priority, setPriority] = useState('')
  const [status, setStatus] = useState('')
  const [assignedUsers, setAssignedUsers] = useState([])
  const [formError, setFormError] = useState(null)

  useEffect(() => {
    if (documents) {
      const options = documents.map((user) => {
        return { label: user.displayName, value: user }
      })
      setUsers(options)
    }
  }, [documents])

  const handleSubmit = async (e) => {
    e.preventDefault()
    setFormError(null)
    if (!category) {
      setFormError('Please set a project category')
      return
    }

    if (assignedUsers.length < 1) {
      setFormError('Please assign the project to at least one user')
      return
    }

    const createdBy = {
      id: user.uid,
      displayName: user.displayName,
      photoURL: user.photoURL
    }

    const assignedUsersList = assignedUsers.map((u) => {
      return {
        id: u.value.id,
        displayName: u.value.displayName,
        photoURL: u.value.photoURL
      }
    })

    const project = {
      name,
      projectType: projectType.value,
      category: category.value,
      priority: priority.value,
      details,
      targetAmount,
      dueDate: timestamp.fromDate(new Date(dueDate)),
      createdBy,
      createdOn: timestamp.fromDate(new Date()),
      assignedUsersList,
      status: status.value,
      comments: [],
      documentation: []
    }

    // create a project in the database
    await addDocument(project)
    if (!response.error) {
      history.push('/')
    }
  }

  return (
    <div className="project-container">
      <Sidebar />
      <div className='create-form'>
        <h2 className="page-title">New project</h2>
        <form onSubmit={handleSubmit}>
          <label>
            <span>Project name:</span>
            <input
              required
              type="text"
              onChange={(e) => setName(e.target.value)}
              value={name} />
          </label>
          <label>
            <span>Type:</span>
            <Select
              options={projecttypes}
              onChange={(option) => setProjectType(option)}
            />
          </label>
          <label>
            <span>Category:</span>
            <Select
              options={categories}
              onChange={(option) => setCategory(option)}
            />
          </label>
          <label>
            <span>Priority:</span>
            <Select
              options={priorities}
              onChange={(p) => setPriority(p)}
              value={priority}
            />
          </label>
          <label>
            <span>Details</span>
            <textarea type="text" cols="30" rows="5"
              onChange={(e) => setDetails(e.target.value)}
              value={details}>
            </textarea>
          </label>
          <hr />
          <label>
            <span>Target Amount:</span>
            <input
              required
              type="number"
              onChange={(e) => setTargetAmount(e.target.value)}
              value={targetAmount} />
          </label>
          <hr />
          <label>
            <span>Due date:</span>
            <input
              required
              type="date"
              onChange={(e) => setDueDate(e.target.value)}
              value={dueDate} />
          </label>
          <hr />
          <label>
            <span>Assign to:</span>
            <Select
              options={users}
              onChange={(option) => setAssignedUsers(option)}
              isMulti
            />
          </label>
          <label>
            <span>Status:</span>
            <Select
              options={statuses}
              onChange={(stat) => setStatus(stat)}
              value={status}
            />
          </label>
          <button className="btn">Add Project</button>
          {formError && <p className='error'>{formError}</p>}
        </form>
      </div>
    </div>

  )
}
