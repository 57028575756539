const questions = [
  {
    id: 1,
    title: '1.Name of organisation and charitable status:',
    info: 'Peace Welfare Trust, is a UK registered Charitable Incorporated Organisation (CIO) under Charity no.1206147.',
    info2: '',
    info3: '',
    info4: '',
  },
  {
    id: 2,
    title: '2.Acquire the property:',
    info: 'Peace Welfare Trust have agreed the purchase of a property for establishing a Masjid.',
    info2: 'The acquisition will occur under this legal entity. The property is the former Fife Street Social Club on 64 Fife Street, Nuneaton.',
    info3: '',
    info4: '',
  },
  {
    id: 3,
    title: '3.The need for another Masjid:',
    info: 'The Muslim population of Nuneaton has increased and expanded geographically in Nuneaton, especially around the Fife street, Manor Court Road area.',
    info2: '',
    info3: '',
    info4: '',
  },
  {
    id: 4,
    title: '4.Provision for Sisters',
    info: 'A dedicated prayer space for our sisters accessible with separate entrance.',
    info2: '',
    info3: '',
    info4: '',
  },
  {
    id: 5,
    title: '5.Nuneaton Muslim Society Executive Committee\'s(NMS EC) Involvement:',
    info: 'The NMS EC are in full support of this project. Peace Welfare Trust have collaborated with NMS EC for the use of NMS platforms for fundraising through Whatsapp groups and Masjid appeals. ',
    info2: 'Both organisations have their own individual distinct governance structures and both are independent of each other.',
    info3: '',
    info4: '',
  },
  {
    id: 6,
    title: '6.Fundraising Strategy:',
    info: 'Engaging businesses locally and nationally for Lillah donations and/or Qard-e-Hasana. ',
    info2: 'Appeal to the community (families & friends) for Lillah donations and/ or Qard - e - Hasana.',
    info3: '',
    info4: '',
  },
  {
    id: 7,
    title: '7.Qard-e-Hasana:',
    info: 'The concept of Qard-e-Hasana is to assist the poor or needy by offering a loan without any interest. ',
    info2: 'The Quran states that such a loan is beautiful and admirable because the borrower of such a loan is God; not the person receiving the money.',
    info3: '',
    info4: '',
  },
  {
    id: 8,
    title: '8.Management of the Masjid:',
    info: 'Presently managed by three holding trustees and a working committee. ',
    info2: 'Once the purchase of the property is completed and governance setup the working forum partcipation will be broadened.',
    info3: '',
    info4: '',
  },
  {
    id: 9,
    title: '9.Suitability of the Property:',
    info: 'The building expands to 7,700 square feet with an adjoining car park for around 30 spaces. It has two large segregated halls on the ground floor, with separate entrances and WC facilities. Includes a three-bedroom flat on the first floor and a spacious basement.',
    info2: 'Renovation will occur in phases for operational readiness.',
    info3: '',
    info4: '',
  },
  {
    id: 10,
    title: '10.Services offered at the new Masjid:',
    info: 'Inshallah there will be five daily salah with dedicated prayer space for sisters, deeni programmes, madrassah classes, segregated library.',
    info2: '',
    info3: '',
    info4: '',
  },
  {
    id: 11,
    title: '11.Immediate and Long-term Plans:',
    info: 'The immediate focus is on securing funds to complete the purchase of the property. ',
    info2: 'The long term aim is to establish a fully functional Masjid for men, women and children.',
    info3: '',
    info4: '',
  },
  {
    id: 12,
    title: '12.Purchase Completion Deadline:',
    info: 'The deadline for completing the purchase is 8th January 2024.',
    info2: '',
    info3: '',
    info4: '',
  },
  {
    id: 13,
    title: '13.Trustees:',
    info: 'The trustees overseeing the project are:',
    info2: 'Hafidh Farid Siddat',
    info3: 'Hafidh Abdullah Ahmedabadi',
    info4: 'Hafidh Zainul Abedin Shaikh',
  },


];

export default questions;