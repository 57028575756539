import React, { useState } from 'react'

// components
import Header from '../../components/header/Header'
import Skills from '../../components/skills/Skills'
import Services from '../../components/services/Services'
import Faqs from '../../components/faqs/Faqs'
import questionsdata from './questionsdata';

// assets
import AboutImage from '../../assets/images/about.jpeg'


// styles
import './Core.css'
import Fundraiser from '../../components/fundraiser/Fundraiser'

export default function Home() {
  const [questions, setQuestions] = useState(questionsdata)

  return (<>

    <div className="page-home">
      <Header />
      <div className="content-divider"></div>
      <Fundraiser targetAmount={400000} currentAmount={400000} />

      <Skills />

      {/* ABOUT SECTION */}
      <section className="section-center about-center clearfix" id="about">
        <article className="about">
          <div className="about-picture-container">
            <img src={AboutImage} alt="about" className="about-picture" />
          </div>
        </article>

        {/* about info */}
        <article className="about">
          <div className="section-title">
            <h2>about <span>us</span></h2>
          </div>
          <p className="about-text">Peace Welfare Trust is a registered Charitable Incorporated Organisation (CIO) (Charity Number 1206147). </p>

          <p className="about-text">The Muslim population of Nuneaton has increased and expanded geographically in Nuneaton, especially around the Fife street, Manor Court Road area. </p>
          <p>To address the growth of our community, Peace Welfare Trust has agreed the purchase of a property for establishing a Masjid. The acquisition will occur under this legal entity. The property is the former Fife Street Social Club on 64 Fife Street, Nuneaton.</p>
        </article>
      </section>

      {/* SERVICES SECTION */}
      <Faqs questions={questions} />

    </div>
  </>
  )
}
