import { React, useState } from 'react'
import { AiOutlineMinus, AiOutlinePlus } from 'react-icons/ai'

// styles
import './Faqs.css'

const Faq = ({ title, info, info2, info3, info4 }) => {

 const [showInfo, setShowInfo] = useState(false);
 return (
  <article className='question'>
   <header>
    <h3>{title}</h3>
    <button className='question-btn' onClick={() => setShowInfo(!showInfo)}>
     {showInfo ? <AiOutlineMinus /> : <AiOutlinePlus />}

    </button>
   </header>
   {showInfo && <p>{info}</p>}
   {showInfo && info2 && <p>{info2}</p>}
   {showInfo && info3 && <p>{info3}</p>}
   {showInfo && info4 && <p>{info4}</p>}

  </article>
 )
}

export default Faq
