import React, { useState, useEffect } from 'react';
import Fundraiserprogressbar from './Fundraiserprogressbar';
// styles
import './Fundraiser.css'


const Fundraiser = ({ name, targetAmount, currentAmount }) => {

 return (

  <section className="faqs clearfix">
   <div className="section-title faqs-title">
    <h3>Progress so far</h3>
    <h2>We have raised</h2>
   </div>
   <div className="section-center clearfix">
    <Fundraiserprogressbar targetAmount={targetAmount} currentAmount={currentAmount} />
    <h2>£{currentAmount.toLocaleString()} of £{targetAmount.toLocaleString()}</h2>
   </div>
  </section>
 );
};

export default Fundraiser;
