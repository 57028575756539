import React from 'react'
import { Link } from 'react-router-dom'

// components
import Header from '../../components/header/Header'
import ContactForm1 from '../../forms/ContactForm1'
import { FaHome, FaEnvelope, FaPhone } from 'react-icons/fa';

// hooks
import { useLogout } from '../../hooks/useLogout'
import { useAuthContext } from '../../hooks/useAuthContext';

// assets

// styles
import './Core.css'

export default function Contact() {
  const { logout, isPending } = useLogout()
  const { user, authIsReady } = useAuthContext()
  return (<>

    <div className="page-home">
      <div className="content-divider"></div>

      {/* ABOUT SECTION */}
      <section className="section-center about-center clearfix" id="about">

        {/* about info */}
        <article className="about">
          <div className="section-title">
            <h2>Get in <span>touch</span></h2>
            <h4>Need some help or advice?</h4>
          </div>

          <div className="contact-info">
            <p className="contact-text">When you need information, help or support, getting straight through to the right person with the right answers is key and makes all the difference.</p>
            <div className="address">
              <FaHome className='icon' />
              <h4>Peace Welfare Trust</h4>
              <p>Charity Number: 1206147</p>
            </div>
            <div className="contact-info contact-details">
              <div className="icon-container">
                <FaPhone className='icon' />
                <span >Hafidh Farid Siddat – 07866 791543</span>
              </div>
              <div className="icon-container">
                <FaPhone className='icon' />
                <span >Hafidh Zainul Abedin Shaikh – 07710 362502</span>
              </div>
              <div className="icon-container">
                <FaPhone className='icon' />
                <span >Hafidh Abdullah Ahmedabadi – 07971 192810</span>
              </div>
              <br></br>
              <div className="icon-container">
                <FaEnvelope className='icon' />
                <span >info@peacewelfaretrust.org.uk</span>
              </div>
            </div>
          </div>
        </article>
        <article className="about">
          <ContactForm1 />
        </article>
      </section>
      <div className="content-divider"></div>


    </div>
  </>
  )
}
