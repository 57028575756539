import React from 'react'

// components
import Skill from './Skill'

// styles
import './Skill.css'

const Skills = () => {
 return (
  <section className="skills clearfix" id="skills">
       <Skill icon="fas fa-crosshairs" icon1="fas fa-check" title="Our Mission" text="To create a community hub that will accommodate Muslims in Nuneaton." text1="Provide support, community resources, Islamic education whilst strengthening
community partnerships." />
       <Skill icon="fas fa-check" icon1="fas fa-check" title="Our Goals" text="A Masjid for 5 daily salaah and islamic programmes." text1="A dedicated prayer service for sisters"
       text2="Serving the community."
       text3="Da'wah work & Education classes"/>

       <Skill icon="fas fa-balance-scale" icon1="fas fa-check" title="The Challenge" 
       
       text="We need to raise 400,000 by 8th January 2024 to complete the purchase of the property."
          text1="OPTION 1: 80 families to raise £5,000 each."
          text2="OPTION 2: Be among 400 families to raise £1,000 each."
          text3="OPTION 3: 800 individuals to donate £500 each." />
       <Skill icon="fas fa-balance-scale" icon1="fas fa-check" title="Monetary Donations"

          text1="CHEQUES: Cheques can be handed to any of the listed individuals on the back page and should be made payable to ‘Peace Welfare Trust’."
          text2="CASH DONATIONS: Cash donations can be made at the Masjid in the Peace Welfare Trust donation boxes and can also be given to the trustees. Please obtain a receipt for your donations."
          text3="" urlText="Donate Online"
          url=""/>
  </section>
 )
}

export default Skills
