import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'

// hooks
import { useCollection } from '../../../hooks/useCollection'
import { useAuthContext } from '../../../hooks/useAuthContext'

// components
import ProjectList from './components/ProjectList'
import Sidebar from '../../../components/navigation/Sidebar';
import ProjectFilter from './components/ProjectFilter'


// styles
import './Projects.css'

export default function Projects() {
  const { documents, error } = useCollection('projects')
  const [currentFilter, setCurrentFilter] = useState('all')
  const { user } = useAuthContext()

  const changeFilter = (newFilter) => {
    setCurrentFilter(newFilter)
  }

  const projects = documents ? documents.filter((document) => {
    switch (currentFilter) {
      case 'all':
        return true

      case 'mine':
        let assignedToMe = false
        document.assignedUsersList.forEach((u) => {
          if (user.uid === u.id) {
            assignedToMe = true
          }
        })
        return assignedToMe

/*       case 'development':
      case 'design':
      case 'marketing':
      case 'sales':
        return document.category === currentFilter */

      default:
        return true
    }
  }) : null

  return (
    <div className='admin-page-container'>
      <Sidebar />
      <div className="project">
        <div className="project-header">

          <h2>Projects</h2>
          <nav className="links">
            <ul>
              <li>
                <NavLink to="/projects/create" className="btn">
                  <span>New Project</span>
                </NavLink>
              </li>
            </ul>
          </nav>
          {documents && (
            <ProjectFilter currentFilter={currentFilter} changeFilter={changeFilter} />
          )}
        </div>
        <div className="project-content">
          {error && <p className='error'>{error}</p>}
          {projects && <ProjectList projects={projects} />}
        </div>
      </div>
    </div>
  )
}
