import { React, useState } from 'react'
// components
import Faq from './faq'
import data from '../../pages/core/questionsdata'

// styles
import './Faqs.css'

const Faqs = ({ questions }) => {

     return (
          <section className="faqs clearfix">
               <div className="section-title faqs-title">
                    <h3>explore</h3>
                    <h2>Frequently Asked Questions</h2>
                    <h3>...</h3>
               </div>
               <div className="section-center clearfix">
                    {questions.map((question) => {
                         return <Faq key={question.id}{...question} />;
                    })}
               </div>
          </section>
     )
}

export default Faqs
