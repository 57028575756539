import React from 'react'

// components
import Header from '../../components/header/Header'
import Skills from '../../components/skills/Skills'
import Services from '../../components/services/Services'

// assets
import AboutImage from '../../assets/images/about.jpeg'

// styles
import './Core.css'

export default function OurServices() {
 return (<>

  <div className="page-home">
   <div className="content-divider"></div>

   {/* SERVICES SECTION */}
   <Services />

  </div>
 </>
 )
}
