import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/storage'


// Add the firebase config here
const firebaseConfig = {
 apiKey: "AIzaSyCBBvBVK4BAy0CE7gFPARmHddEtUy4t898",
 authDomain: "peace-welfare-trust-website.firebaseapp.com",
 projectId: "peace-welfare-trust-website",
 storageBucket: "peace-welfare-trust-website.appspot.com",
 messagingSenderId: "14821312507",
 appId: "1:14821312507:web:95142f406f6d45f41cad5e",
 measurementId: "G-WXVV0G7FSJ"
};

// init firebase
firebase.initializeApp(firebaseConfig)

// init services
const projectFirestore = firebase.firestore()
const projectAuth = firebase.auth()
const projectStorage = firebase.storage()

// timestamp
const timestamp = firebase.firestore.Timestamp

export { projectFirestore, projectAuth, projectStorage, timestamp }